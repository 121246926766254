import { Injectable, afterNextRender, inject, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MapGeocoderResponse } from '@angular/google-maps';
import { AutocompleteFormControls } from '@enums/form-controls.enum';
import { GetCurrentAddress } from '@functions/get-current-address.function';
import { untilDestroyed } from '@functions/until-destroyed.function';
import { LocalizationResponse } from '@interfaces/responses/localization-response.interface';
import { StorageService } from '@services/storage.service';
import { LatLngLiteral } from 'ngx-google-places-autocomplete/objects/latLng';
import { Observable, Subscriber, debounceTime } from 'rxjs';

const SEARCH_EMITTER_DEBAUNCE = 500;
const LOCALIZATION_TIMEOUT = 5000;

/**
 * Service responsible for handling address search functionality.
 */
@Injectable()
export class AddressSearchService {
  private readonly storageService = inject(StorageService);
  private autocomplete!: google.maps.places.AutocompleteService;

  readonly untilDestroyed = untilDestroyed();

  readonly form: FormGroup = this.initializeAutocompleteForm();
  readonly predictions = signal<google.maps.places.AutocompletePrediction[] | null>(null);
  readonly lozalizing = signal<boolean>(false);
  readonly navigating = signal<boolean>(false);

  AutocompleteFormControls = AutocompleteFormControls;

  constructor() {
    afterNextRender(() => {
      this.autocomplete = new google.maps.places.AutocompleteService();
    });
  }

  /**
   * Handles the search predictions based on the form value changes.
   */
  public handleSearchPredictions(): void {
    this.form.valueChanges
      .pipe(debounceTime(SEARCH_EMITTER_DEBAUNCE), this.untilDestroyed())
      .subscribe((params: { search: string }) => {
        this.getPlacePredictions(params.search);
      });
  }

  /**
   * Localizes the address search service.
   * @returns An Observable that emits a LocalizationResponse.
   */
  public onLozalize(): Observable<LocalizationResponse> {
    this.lozalizing.set(true);
    return this.getCurrentPosition();
  }

  /**
   * Sets the address value in the form.
   * @param address - The address to set.
   */
  public setAddress(address: string): void {
    this.form.get(AutocompleteFormControls.SEARCH)?.setValue(address);
  }

  /**
   * Retrieves the address from the form control.
   * @returns The address value if available, otherwise null.
   */
  public getAddress(): string | null {
    return this.form.get(AutocompleteFormControls.SEARCH)?.value || null;
  }

  /**
   * Returns the AutocompleteService instance.
   * @returns The AutocompleteService instance.
   */
  private getAutocomplete(): google.maps.places.AutocompleteService {
    return this.autocomplete;
  }

  /**
   * Initializes the autocomplete form.
   * @returns The initialized form group.
   */
  private initializeAutocompleteForm(): FormGroup {
    return new FormGroup({
      [AutocompleteFormControls.SEARCH]: new FormControl(GetCurrentAddress(this.storageService)),
    });
  }

  /**
   * Retrieves place predictions based on the provided input.
   * @param input - The input string used for predictions.
   */
  private getPlacePredictions(input: string): void {
    this.getAutocomplete().getPlacePredictions(
      { input },
      (predictions: google.maps.places.AutocompletePrediction[] | null) => {
        this.predictions.set(predictions);
      }
    );
  }

  /**
   * Retrieves the current position of the user.
   * @returns An Observable that emits a LocalizationResponse object.
   */
  private getCurrentPosition(): Observable<LocalizationResponse> {
    return new Observable((subscriber: Subscriber<LocalizationResponse>) => {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) =>
          this.handleLocalizeSuccess(position).then((response: MapGeocoderResponse) => {
            switch (response.status) {
              case google.maps.GeocoderStatus.OK:
                subscriber.next({
                  status: google.maps.GeocoderStatus.OK,
                  address: response.results[0].formatted_address,
                  address_components: response.results[0].address_components,
                });
                break;
              case google.maps.GeocoderStatus.ZERO_RESULTS:
                subscriber.next({ status: google.maps.GeocoderStatus.ZERO_RESULTS, address: undefined });
                break;
              default:
                subscriber.next({ status: google.maps.GeocoderStatus.ERROR, address: undefined });
                break;
            }
            this.lozalizing.set(false);
          }),
        (error: GeolocationPositionError) =>
          this.handleLocalizeError(error).then((response: GeolocationPositionError) => {
            subscriber.next({
              status: response.code,
              address: undefined,
            });
          }),
        {
          enableHighAccuracy: true,
          timeout: LOCALIZATION_TIMEOUT,
        }
      );
    });
  }

  /**
   * Handles the success of geolocation localization.
   * @param position - The geolocation position object.
   * @returns A promise that resolves to a MapGeocoderResponse object.
   */
  private handleLocalizeSuccess(position: GeolocationPosition): Promise<MapGeocoderResponse> {
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    const location: LatLngLiteral = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        { location },
        (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
          if (results) {
            resolve({ status, results });
          }
        }
      );
    });
  }

  /**
   * Handles the error that occurs during geolocation localization.
   * @param error - The error object representing the geolocation position error.
   * @returns A promise that resolves with the geolocation position error.
   */
  private handleLocalizeError(error: GeolocationPositionError): Promise<GeolocationPositionError> {
    this.lozalizing.set(false);
    return new Promise((resolve, reject) => {
      resolve(error);
    });
  }

  /**
   * Retrieves the details of a place using its placeId.
   * @param placeId - The placeId of the location.
   * @returns A Promise that resolves to a MapGeocoderResponse object containing the status and results.
   */
  public getPlaceDetails(placeId: string): Promise<MapGeocoderResponse> {
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        { placeId },
        (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
          if (results) {
            resolve({ status, results });
          }
        }
      );
    });
  }

  /**
   * Resets the form and clears the predictions.
   */
  public reset(): void {
    this.form.reset();
    this.predictions.set(null);
  }
}
