import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, input, output, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MapGeocoderResponse } from '@angular/google-maps';
import { Router } from '@angular/router';
import { TrackInputFocusDirective } from '@directives/track-input-focus.directive';
import { AddressSearchLocation } from '@enums/address-search-type.enum';
import { LocalizationResponse } from '@interfaces/responses/localization-response.interface';
import { Address } from '@models/address.model';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsService } from '@services/notifications.service';
import { StorageService } from '@services/storage.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AddressSearchService } from './address-search.service';

/**
 * Array of PrimeNG modules used in the address search component.
 */
const PRIME_NG_MODULES = [InputTextModule, OverlayPanelModule, IconFieldModule, InputIconModule, ButtonModule];

/**
 * Component for address search functionality.
 * This component allows users to search for addresses using autocomplete predictions.
 * It provides options for selecting an address, handling prediction success and error, and navigating to the restaurant list.
 */
@Component({
  selector: 'app-address-search',
  standalone: true,
  imports: [
    PRIME_NG_MODULES,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    SvgIconComponent,
    TrackInputFocusDirective,
  ],
  templateUrl: './address-search.component.html',
  styleUrl: './address-search.component.scss',
  providers: [AddressSearchService],
})
export class AddressSearchComponent implements OnInit {
  /**
   * Event emitted when an address is selected.
   * The event payload is an array of address components.
   */
  onAddressSelect = output<google.maps.GeocoderAddressComponent[]>();

  /**
   * Event emitted when a search is performed.
   * The event payload is the search query string or null if no search is performed.
   */
  onSearch = output<string | null>();

  /**
   * The location where the address search is being used.
   * This determines the behavior of the component based on the location.
   */
  addressSearchLocation = input<AddressSearchLocation>(AddressSearchLocation.HOMEPAGE);

  /**
   * Service for handling notifications.
   */
  private readonly notificationsService = inject(NotificationsService);
  /**
   * Service for managing storage operations.
   */
  readonly storageService = inject(StorageService);
  /**
   * Service for searching addresses.
   */
  readonly addressSearchService = inject(AddressSearchService);
  /**
   * The router instance used for navigation.
   */
  readonly router = inject(Router);

  /**
   * Enumeration of possible address search locations.
   */
  AddressSearchLocation = AddressSearchLocation;

  /**
   * Indicates whether the panel is open or not.
   */
  isPanelOpen = signal<boolean>(false);

  /**
   * Initializes the component.
   * This method is called after the component has been created and initialized.
   */
  ngOnInit(): void {
    this.addressSearchService.handleSearchPredictions();
  }

  /**
   * Handles the selection of a prediction.
   * Depending on the address search location, it performs different actions.
   * @param prediction - The selected prediction.
   */
  onPredictionSelect(prediction: google.maps.places.AutocompletePrediction): void {
    switch (this.addressSearchLocation()) {
      case AddressSearchLocation.ACCOUNT:
        this.getPlaceDetails(prediction);
        break;
      case AddressSearchLocation.TOPBAR:
        this.addressSearchService.setAddress(prediction.description);
        break;
      default:
        this.handlePredictionSuccess(prediction.description);
        break;
    }
    this.isPanelOpen.set(false);
  }

  /**
   * Retrieves place details for a prediction and emits the selected address.
   * @param prediction - The selected prediction.
   */
  getPlaceDetails(prediction: google.maps.places.AutocompletePrediction): void {
    this.addressSearchService.getPlaceDetails(prediction.place_id).then((response: MapGeocoderResponse) => {
      if (response.status === google.maps.GeocoderStatus.OK) {
        this.onAddressSelect.emit(response.results[0].address_components);
      }
    });
  }

  /**
   * Handles the localization of a prediction.
   * It performs different actions based on the localization response status.
   */
  onLocalizePrediction(): void {
    this.addressSearchService
      .onLozalize()
      .pipe(this.addressSearchService.untilDestroyed())
      .subscribe((response: LocalizationResponse) => {
        switch (response.status) {
          case google.maps.GeocoderStatus.OK:
            switch (this.addressSearchLocation()) {
              case AddressSearchLocation.TOPBAR:
                this.addressSearchService.setAddress(response.address!);
                break;
              case AddressSearchLocation.ACCOUNT:
                this.onAddressSelect.emit(response.address_components!);
                break;
              default:
                this.handlePredictionSuccess(response.address!);
                break;
            }
            break;
          default:
            this.handlePredictionError(response.status);
            break;
        }
        this.isPanelOpen.set(false);
      });
  }

  /**
   * Handles the selection of a user-defined address.
   * Depending on the address search location, it performs different actions.
   * @param address - The selected user address.
   */
  onUserAddressSelect(address: Address): void {
    const userAddress = `${address.city}, ${address.street} ${address.building_number}`;
    if (this.addressSearchLocation() === AddressSearchLocation.TOPBAR) {
      this.addressSearchService.setAddress(userAddress);
    } else {
      this.handlePredictionSuccess(userAddress);
    }
    this.isPanelOpen.set(false);
  }

  /**
   * Handles the success of a prediction.
   * It sets the current address in the storage and updates the address in the address search service.
   * @param address - The selected address.
   */
  handlePredictionSuccess(address: string): void {
    this.storageService.setCurrentAdress(address);
    this.addressSearchService.setAddress(address);
  }

  /**
   * Handles the selection of the current address.
   * It updates the address in the address search service.
   * @param address - The selected address.
   */
  onCurrentAddressSelect(address: string): void {
    this.addressSearchService.setAddress(address);
    this.isPanelOpen.set(false);
  }

  /**
   * Navigates to the restaurant list based on the address search location.
   * If the location is TOPBAR, it sets the current address in the storage and emits the search event.
   * Otherwise, it navigates to the restaurant list page with the city query parameter.
   */
  navigateToRestaurantList(): void {
    const address = this.addressSearchService.getAddress();
    switch (this.addressSearchLocation()) {
      case AddressSearchLocation.TOPBAR:
        this.storageService.setCurrentAdress(address!);
        this.onSearch.emit(address);
        break;
      case AddressSearchLocation.ACCOUNT:
        break;
      default:
        if (!this.storageService.getCurrentAddress() && address) {
          this.storageService.setCurrentAdress(address);
        }
        this.addressSearchService.navigating.set(true);
        this.router.navigate(['/restaurants/list'], { queryParams: { city: address } });
        break;
    }
  }

  /**
   * Handles the error of a prediction.
   * It displays a notification based on the error status.
   * @param status - The error status.
   */
  handlePredictionError(status: google.maps.GeocoderStatus | number): void {
    switch (status) {
      case google.maps.GeocoderStatus.ZERO_RESULTS:
        this.notificationsService.warning('Autocomplete.ZERO_RESULTS');
        break;
      case google.maps.GeocoderStatus.ERROR:
        this.notificationsService.error('Autocomplete.ERROR');
        break;
      case GeolocationPositionError.PERMISSION_DENIED:
        this.notificationsService.error('Autocomplete.PERMISSION_DENIED');
        break;
      case GeolocationPositionError.POSITION_UNAVAILABLE:
        this.notificationsService.error('Autocomplete.POSITION_UNAVAILABLE');
        break;
      case GeolocationPositionError.TIMEOUT:
        this.notificationsService.error('Autocomplete.ERROR');
        break;
    }
  }
}
