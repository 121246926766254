<div class="address-search" [ngClass]="'address-search-' + addressSearchLocation()">
  <div class="address-search-form">
    <form [formGroup]="addressSearchService.form" #form>
      <p-iconField
        class="icon-field-both"
        [trackInputFocus]="autocompleteInput"
        [class.is-focused]="autocompleteInput.value">
        <p-inputIcon class="icon-field-both-icon icon-field-both-left">
          <svg-icon src="assets/icons/pencil.svg"></svg-icon>
        </p-inputIcon>
        <input
          #autocompleteInput
          autocomplete="off"
          id="autocomplete-input"
          placeholder="{{ 'Placeholders.Search address' | translate }}"
          type="text"
          pInputText
          (focus)="isPanelOpen.set(true)"
          [formControlName]="addressSearchService.AutocompleteFormControls.SEARCH" />
        <p-inputIcon
          class="icon-field-both-icon icon-field-both-right pointer"
          [class.is-disabled]="addressSearchService.navigating()"
          (click)="
            addressSearchLocation() === AddressSearchLocation.ACCOUNT
              ? isPanelOpen.set(true)
              : autocompleteInput.value
                ? navigateToRestaurantList()
                : null
          ">
          @if (addressSearchService.navigating()) {
            <i class="pi pi-spin pi-spinner"></i>
          } @else {
            <svg-icon src="assets/icons/search.svg"></svg-icon>
          }
        </p-inputIcon>
        @if (autocompleteInput.value) {
          <svg-icon
            class="pointer clear-input-icon"
            (click)="addressSearchService.reset()"
            src="assets/icons/close-circle.svg"></svg-icon>
        }
      </p-iconField>
    </form>
  </div>
  <div class="address-search-overlay">
    @if (isPanelOpen()) {
      <div
        class="overlay-panel"
        [ngClass]="
          addressSearchLocation() === AddressSearchLocation.ACCOUNT
            ? 'address-search-overlay-panel address-search-overlay-panel-account'
            : 'address-search-overlay-panel'
        ">
        <div class="address-search-close">
          <p-button
            [rounded]="true"
            icon="pi pi-times"
            size="large"
            styleClass="no-shadow p-button-white button-close-modal"
            (onClick)="isPanelOpen.set(false)"></p-button>
        </div>
        <div class="predictions">
          @if (addressSearchService.predictions()) {
            <div class="predictions-list">
              @for (prediction of addressSearchService.predictions(); track $index) {
                <div class="predictions-item" [class.is-first]="$index === 0" (click)="onPredictionSelect(prediction)">
                  <svg-icon src="assets/icons/target.svg"></svg-icon>
                  <span>{{ prediction.description }}</span>
                </div>
              }
            </div>
          } @else {
            <div class="predictions-list">
              <div class="predictions-item predictions-item-empty">{{ 'Autocomplete.No results' | translate }}</div>
            </div>
          }
          @if (
            addressSearchLocation() === AddressSearchLocation.HOMEPAGE ||
            addressSearchLocation() === AddressSearchLocation.TOPBAR
          ) {
            @if (storageService.getCurrentAddress(); as currentAddress) {
              <div class="predictions-section">
                <div class="predictions-section-title">{{ 'Autocomplete.Current address' | translate }}</div>
                <div class="predictions-item predictions-item-current" (click)="onCurrentAddressSelect(currentAddress)">
                  <svg-icon src="assets/icons/target.svg"></svg-icon>
                  <span>{{ currentAddress }}</span>
                </div>
              </div>
            }
            @if (storageService.getUser()?.addresses; as userAddresses) {
              <div class="predictions-section">
                <div class="predictions-section-title">{{ 'Autocomplete.Proposed' | translate }}</div>
                @for (address of userAddresses; track $index) {
                  <div class="predictions-item" (click)="onUserAddressSelect(address)">
                    <svg-icon src="assets/icons/target.svg"></svg-icon>
                    <span>{{ address.city }}, {{ address.street }} {{ address.building_number }}</span>
                  </div>
                }
              </div>
            }
          }
          <div class="predictions-item predictions-item-localize" (click)="onLocalizePrediction()">
            @if (addressSearchService.lozalizing()) {
              <span class="predictions-item-localize-icon">
                <i class="pi pi-spin pi-spinner"></i>
              </span>
            } @else {
              <svg-icon src="assets/icons/pin.svg"></svg-icon>
            }
            <span>{{ 'Autocomplete.Share your location' | translate }}</span>
          </div>
        </div>
      </div>
    }
  </div>
</div>
@if (addressSearchLocation() === AddressSearchLocation.HOMEPAGE) {
  <div
    class="predictions-item predictions-item-localize predictions-item-localize-addon"
    (click)="onLocalizePrediction()">
    @if (addressSearchService.lozalizing()) {
      <span class="predictions-item-localize-icon">
        <i class="pi pi-spin pi-spinner"></i>
      </span>
    } @else {
      <svg-icon src="assets/icons/pin.svg"></svg-icon>
    }
    <span>{{ 'Autocomplete.Share your location' | translate }}</span>
  </div>
}
